import { AxiosRequestConfig } from 'axios';
import { createSingleContent, updateSingleContent } from '@/lib/apiService';

export const saveMembershipFormData = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const response = await createSingleContent('membership_form', data, config);
  return response;
};

export const savePresskitData = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const response = await createSingleContent('new_collection', data, config);

  return response;
};

export const saveContactData = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const respose = await createSingleContent('contact_us_form', data, config);
  return respose;
};

export const recentReportDownloadData = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const response = await createSingleContent(
    'recent_reports_download_form',
    data,
    config
  );

  return response;
};
export const sebiCircularReportDownload = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: {
    name: string;
    email: string;
    fund_name: string;
    designation: string;
    mobile_number: string;
  };
}) => {
  const response = await createSingleContent(
    'sebi_circular_report_download',
    data,
    config
  );
  return response;
};
export const subscribeCardData = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const response = await createSingleContent('newsletter_form', data, config);

  return response;
};

export const createJob = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const response = await createSingleContent('jobs', data, config);
  return response;
};

export const createCompany = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const response = await createSingleContent('companies', data, config);
  return response;
};

export const editCompany = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const response = await updateSingleContent(
    'companies',
    data.slug,
    data,
    config
  );
  return response;
};

export const editJob = async ({
  data,
  config,
}: {
  config?: AxiosRequestConfig;
  data: any;
}) => {
  const response = await updateSingleContent('jobs', data.slug, data, config);
  return response;
};
