import type { AxiosRequestConfig } from "axios";
import type { LevoQuery } from "@/interfaces/levo-query";
import {
	type LevoResponseList,
	type LevoResponseSingle,
	levoAxios,
} from "@/lib/http";

export const getContentList = async <T = Record<string, any>>(
	key: string,
	data: LevoQuery.FindQuery = {},
	config?: AxiosRequestConfig,
) => {
	const response = await levoAxios.post<LevoResponseList<T>>(
		`/bevy/content/${key}/query`,
		data,
		config,
	);
	return response.data;
};

export const getSingleContent = async <T = Record<string, any>>(
	key: string,
	slug: string,
	config?: AxiosRequestConfig,
) => {
	const response = await levoAxios.get<LevoResponseSingle<T>>(
		`/bevy/content/${key}/${slug}`,
		config,
	);
	return response.data;
};

export const createSingleContent = async <T = Record<string, any>>(
	key: string,
	data: any,
	config?: AxiosRequestConfig,
) => {
	const response = await levoAxios.post<LevoResponseSingle<T>>(
		`/bevy/content/${key}`,
		data,
		config,
	);
	return response.data;
};

export const updateSingleContent = async <T = Record<string, any>>(
	key: string,
	slug: string,
	data: any,
	config?: AxiosRequestConfig,
) => {
	const response = await levoAxios.put<LevoResponseSingle<T>>(
		`/bevy/content/${key}/${slug}`,
		data,
		config,
	);
	return response.data;
};
