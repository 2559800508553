import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  Heading,
  Button,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { IoMdClose } from 'react-icons/io';
import { FiArrowUpRight } from 'react-icons/fi';
import Link from 'next/link';

import { useAppDispatch } from '@/store';
import { login } from '@/store/slices/userSlice';
import { errorHandler } from '@/lib/http';
import { loginUser } from '@/services/levo';
import FormInput from '../formComponents/FormInput';

interface ILoginModalProps {
  isOpen: boolean;
  disableClose?: boolean;
  onClose: () => void;
  closeOnOverlayClick?: boolean;
}

interface IFormValues {
  password: string;
  email: string;
}
const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required.'),
  password: yup.string().required(' Password is required.'),
});

const LoginModal: React.FunctionComponent<ILoginModalProps> = ({
  isOpen,
  onClose,
  closeOnOverlayClick = true,
  disableClose = false,
}) => {
  const dispatch = useAppDispatch();

  const { isLoading, mutate } = useMutation(loginUser, {
    onSuccess: (data) => {
      dispatch(login(data));
    },
    onError: (err: any) => {
      errorHandler(err);
    },
    onSettled: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
      onClose();
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<IFormValues>({
    initialValues: {
      password: '',
      email: '',
    },
    validationSchema,
    onSubmit(value) {
      mutate({ data: { ...value, username: value.email } });
    },
  });

  return (
    <Modal
      isCentered
      onClose={onClose}
      size="xl"
      isOpen={isOpen}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="0"
        px={{ xs: '5px', sm: '15px', lg: '40px' }}
        py={{ xs: '15px', sm: '25px', md: '45px' }}
        bg="white"
        mx={{ xs: '10px', md: '0' }}
      >
        {!disableClose && (
          <Button
            pos="absolute"
            variant="unstyled"
            right="2"
            top="2"
            aria-label="close Modal"
            bgColor="transparent"
            cursor="pointer !important"
            _focusVisible={{ outline: 'none' }}
            onClick={onClose}
          >
            <IoMdClose size="1.7rem" color="rgba(100, 100, 140, 1)" />
          </Button>
        )}
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Heading
              variant="loginHeading"
              pb={{ xs: '10px', sm: '15px', md: '35px' }}
              fontSize={{ xs: '1.3rem', sm: '1.6rem ', md: '1.9rem' }}
            >
              Sign in to your account
            </Heading>
            <Flex
              columnGap="30px"
              flexDir="column"
              rowGap={{ xs: '12px', sm: '18px', md: '24px' }}
            >
              <FormInput
                name="email"
                width="100%"
                label="Email Address  / Username"
                placeholder="Enter your Email Address or Username"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                styleType="recentReports"
                isRequired
                touched={touched.email}
              />
              <FormInput
                password
                name="password"
                width="100%"
                label="Password"
                placeholder="Enter your Password"
                styleType="recentReports"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                isRequired
                touched={touched.password}
                type="password"
              />
              <Button
                rightIcon={<FiArrowUpRight size="20px" color="white" />}
                py="1.6rem"
                colorScheme="primary"
                fontSize="15px"
                fontWeight="700"
                lineHeight="150%"
                letterSpacing="0.02em"
                borderRadius="0px"
                h="47px"
                type="submit"
                isLoading={isLoading}
              >
                Sign in
              </Button>
              <Text
                as="span"
                textAlign="center"
                fontWeight="600"
                fontSize="14px"
                lineHeight="150%"
                letterSpacing="0.01em"
                color="rgba(61, 61, 95, 0.78)"
                pt="11px"
              >
                {' '}
                Not a member yet ?
                <Text
                  as="span"
                  textAlign="center"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="150%"
                  letterSpacing="0.01em"
                  color="primary.500"
                  onClick={() => onClose()}
                  cursor="pointer"
                >
                  <Link href="/membership/membership-form"> Sign Up</Link>{' '}
                </Text>
              </Text>
            </Flex>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
