import { Container, ContainerProps } from "@chakra-ui/react";
import React from "react";

interface Props extends ContainerProps {
  fluid?: boolean;
  children: React.ReactNode;
  props?: any;
}

const SectionContainer: React.FC<Props> = ({ children, fluid, ...props }) => (
    <Container
      maxW={fluid ? "none" : "1440px"}
      mx="auto"
      pl={{xs:'16px',sm:'30px',md:'45px', lg:'60px',xl:"100px"}}
      pr={{xs:'20px',md:"45px"}}
      width="100%"
      {...props}
    >
      {children}
    </Container>
  );

export default SectionContainer;
