import React from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';

import * as yup from 'yup';
import { FiArrowUpRight } from 'react-icons/fi';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import FormInput from '../formComponents/FormInput';
import { validations } from '@/lib/validations';
import { subscribeCardData } from '@/services/form';
import { errorHandler } from '@/lib/http';
import { successToast } from '../Toasts';

const validationSchema = yup.object().shape({
  email_address: validations.email
    .email('Invalid email address')
    .required('Email is required'),
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  organisation: yup.string().required('Organization name is required'),
});

const SubscribeCard: React.FC = () => {
  const { mutate, isLoading } = useMutation(subscribeCardData, {
    onSuccess: () => {
      successToast('Your data has been successfully submitted');
      resetForm();
    },
    onError: (err: any) => {
      errorHandler(err);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<{
    first_name: string;
    last_name: string;
    organisation: string;
    email_address: string;
  }>({
    initialValues: {
      first_name: '',
      last_name: '',
      organisation: '',
      email_address: '',
    },

    validationSchema,
    onSubmit(value) {
      mutate({ data: value });
    },
  });

  return (
    <Container
      display="flex"
      flexDir="column"
      maxW="container.xl"
      minH="472px"
      mx="auto"
      mb="68px"
      as="main"
    >
      <Flex
        pt={{ xs: '20px', md: '70px' }}
        pl={{ xs: '20px', md: '83px' }}
        pr={{ xs: '20px', md: '84px' }}
        pb={{ xs: '20px', md: '20px' }}
        bgColor="text.mediumBlack"
        justify="space-between"
        borderTopRadius="4px"
        boxShadow="0px 4px 124px rgba(0, 0, 0, 0.04)"
      >
        <Heading as="h2" variant="queryContact" lineHeight="42px">
          Stay Connected.
          <br />
          Sign up for updates.
        </Heading>
      </Flex>
      <Box
        pt="30px"
        px={{ xs: '20px', md: '82px' }}
        w="100%"
        pb="59px"
        bgColor="bg.cardLightBlack"
        borderBottomRadius="4px"
        boxShadow="0px 4px 124px rgba(0, 0, 0, 0.04)"
      >
        <form onSubmit={handleSubmit}>
          <SimpleGrid
            gap="35px 40px"
            mb="30px"
            w="100%"
            columns={{ xs: 1, md: 2 }}
          >
            <FormInput
              width="100%"
              placeholder="First Name"
              name="first_name"
              value={values.first_name}
              error={errors.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.first_name}
            />
            <FormInput
              width="100%"
              placeholder="Last Name"
              name="last_name"
              value={values.last_name}
              error={errors.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.last_name}
            />
            <FormInput
              width="100%"
              placeholder="Organisation"
              name="organisation"
              value={values.organisation}
              error={errors.organisation}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.organisation}
            />
            <FormInput
              width="100%"
              placeholder="E-mail Address"
              name="email_address"
              value={values.email_address}
              error={errors.email_address}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.email_address}
            />
          </SimpleGrid>

          <Button
            w="159.85px"
            h="55px"
            color="white"
            bgColor="primary.500"
            borderRadius="4px"
            rightIcon={<FiArrowUpRight size="15px" color="white" />}
            type="submit"
            isLoading={isLoading}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default SubscribeCard;
