/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Link, LinkProps } from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

const LinkWrapperV2: React.FC<{
  link?: string;
  external?: boolean;
  children: React.ReactNode;
  chakraLinkProps?: LinkProps;
  nextLinkProps?: Omit<NextLinkProps, 'href'>;
}> = ({ link, external = false, children, chakraLinkProps, nextLinkProps }) => (
  <>
    {link && link !== '' ? (
      <NextLink legacyBehavior href={link} passHref {...nextLinkProps}>
        <Link isExternal={external} {...chakraLinkProps}>
          {children}
        </Link>
      </NextLink>
    ) : (
      children
    )}
  </>
);

export default LinkWrapperV2;
