import { createStandaloneToast } from '@chakra-ui/react';

export const errorToast = (message: string) => {
  const { toast } = createStandaloneToast();
  toast({
    title: 'Error',
    description: message,
    status: 'error',
    duration: 4000,
    isClosable: true,
    position: 'top',
  });
};

export const successToast = (message: string) => {
  const { toast } = createStandaloneToast();
  toast({
    title: 'Success',
    description: message,
    status: 'success',
    duration: 4000,
    isClosable: true,
    position: 'top',
  });
};
