import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  type InputProps,
  type FormControlProps,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export interface IFormInputProps {
  name: string;
  width: string;
  label?: React.ReactNode;
  placeholder?: string;
  password?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: any;
  error?: any;
  touched?: boolean;
  helper_text?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: InputProps;
  wrapperProps?: FormControlProps;
  children?: React.ReactNode;
  isRequired?: boolean;
  styleType?: string;
}

const FormInput: React.FC<IFormInputProps> = ({
  name,
  label,
  placeholder,
  type = 'text',
  value,
  onChange,
  onBlur,
  error,
  touched,
  helper_text,
  width,
  inputProps = {},
  wrapperProps = {},
  isRequired,
  password,
}) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <FormControl
      width={width}
      isInvalid={Boolean(error && touched)}
      isRequired={isRequired}
      {...wrapperProps}
    >
      <FormLabel
        fontSize="15px"
        fontWeight="800"
        lineHeight="22.5px"
        letterSpacing="-0.02em"
        color="text.darkGray"
      >
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          name={name}
          placeholder={placeholder}
          _placeholder={{
            opacity: 1,
            color: 'text.placeholder',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.02em',
          }}
          color="text.mediumBlack"
          bgColor="white"
          boxShadow="0px 4px 84px rgba(100, 100, 140, 0.13)"
          borderRadius="5px"
          fontSize="16px"
          lineHeight="24px"
          fontWeight="600"
          type={password && show ? 'text' : type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          width="100%"
          height="52px"
          pt="15px"
          pb="13px"
          pl="21px"
          focusBorderColor="primary.500"
          errorBorderColor="errorRed"
          _active={{ border: '1px solid', borderColor: 'primary.500' }}
          border="1px solid"
          // borderColor={error && touched ? '' : 'inputBorder !important'}

          {...inputProps}
        />
        {password && (
          <InputRightElement width="3rem" bgColor="transparent" h="48px">
            <Button
              h="46px"
              onClick={handleClick}
              px="0px"
              bgColor="transparent"
              _active={{ bgColor: 'transparent' }}
              _hover={{ bgColor: 'transparent' }}
            >
              {show ? (
                <AiOutlineEyeInvisible
                  size="22px"
                  color="rgba(127, 127, 165, 1)"
                />
              ) : (
                <AiOutlineEye size="22px" color="rgba(127, 127, 165, 1)" />
              )}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {helper_text && (
        <FormHelperText
          fontSize="14px"
          fontWeight="400"
          lineHeight="21px"
          letterSpacing="0.02em"
          color="text.darkGray"
        >
          {helper_text}
        </FormHelperText>
      )}
      {error && <FormErrorMessage color="errorRed">{error}</FormErrorMessage>}
    </FormControl>
  );
};

FormInput.displayName = 'FormInput';

FormInput.defaultProps = {
  error: '',
  isRequired: false,
  type: 'text',
};

export default FormInput;
