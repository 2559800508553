import { AxiosRequestConfig } from "axios";
import { ILevoMembership, LevoMembership } from "@levo-so/membership";
import { levoAxios } from "../lib/http";

export type FieldType = string | number | Date | boolean | null;

export type ComparableFieldType = number | Date;

export type FilterOperatorGreaterThan =
	| {
			gt?: ComparableFieldType;
	  }
	| {
			gte?: ComparableFieldType;
	  };

export type FilterOperatorLessThan =
	| {
			lt?: ComparableFieldType;
	  }
	| {
			lte?: ComparableFieldType;
	  };

export type FilterOperator =
	| {
			equals?: FieldType;
	  }
	| {
			not?: FieldType;
	  }
	| {
			in?: FieldType[];
	  }
	| {
			not_in?: FieldType[];
	  }
	| (FilterOperatorGreaterThan & FilterOperatorLessThan)
	| {
			contains?: string;
	  }
	| {
			not_contains?: string;
	  }
	| {
			starts_with?: string;
	  }
	| { not_starts_with?: string }
	| { ends_with?: string }
	| { not_ends_with?: string }
	| { empty?: boolean } // kind: array-*, uses $exists and $size
	| { has?: Record<string, FieldType> }; // kind: group, record / uses $elemMatch

export type LogicalFilter =
	| {
			AND: Filter[];
			OR?: undefined;
	  }
	| {
			OR: Filter[];
			AND?: undefined;
	  };

export type FieldFilter = Record<string, FilterOperator | FieldType>;

export type Filter = LogicalFilter | FieldFilter;

export type LevoFindQuery = {
	page?: number;
	limit?: number;
	select?: Record<string, boolean>;
	where?: Filter;
	sort?: {
		[field: string]: "asc" | "desc";
	};
	/**
	 * populate.path - key for Object.keys(include)
	 * populate.match - transformFilters(include.where)
	 * populate.sort - transformFilters(include.sort)
	 * populate.select - transformFilters(include.select)
	 * populate.populate - transformFilters(include.include)
	 */
	include?: Record<
		string,
		Pick<LevoFindQuery, "select" | "where" | "sort" | "include" | "limit">
	>;
	search?: string;
};

export type LevoQueryResponse<T> = {
	meta: {
		total: number;
		pages: number;
	};
	data: T[];
};

export const getLevoContent = async (
	key: string,
	query?: LevoFindQuery,
	config?: AxiosRequestConfig,
) => {
	const response = await levoAxios.get(`/content/${key}/query`, {
		...config,
		params: {
			...config?.params,
			query: JSON.stringify(query || {}),
		},
	});
	return response.data;
};

export const loginUser = async ({
	data,
}: {
	data: { email: string; password: string; username: string };
	config?: AxiosRequestConfig;
}): Promise<ILevoMembership.Account | null> => {
	const response = await LevoMembership.signInWithPassword(data);
	return response;
};
