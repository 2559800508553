import * as Yup from 'yup';

export const validations = {
  email: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9.!#$%&*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      'Invalid email address'
    ),
  password: Yup.string()
    .trim()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Incorrect password'
    ),
  newPassword: Yup.string()
    .trim()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character.'
    ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Password does not match'
  ),
};
